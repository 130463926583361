<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Profile"></Icon>
        ثبت نام
      </h2>
      <p class="mt-3">
        برای سادگی در ثبت نام بدون شماره موبایل یا ایمیل می‌توانید از یکی از ربات ها
        استفاده کنید
      </p>
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-5/12 sm:pl-3" v-if="false">
          <MnrText title="نام" inputDir="ltr"></MnrText>
          <MnrText title="موبایل یا ایمیل" class="mt-3"></MnrText>
          <MnrPassword title="رمز عبور" inputDir="ltr" class="mt-3"></MnrPassword>
          <!-- <button class="bg-coolGray-500 block p-3 w-full rounded-md mt-5">ورود</button> -->
          <Button class="mt-5">ثبت نام</Button>
          <Button to="/login" class="mt-5" color="blueGray" :light="true">ورود</Button>
        </div>
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-5/12 sm:pr-3 mt-5">
          <p><b>در حال حاظر تنها توسط رباتها می‌توانید ثبت نام کنید</b></p>
          <p class="text-justify">
            می‌توانید با استفاده از یکی از رباتها به راحتی ثبت نام کرده و وارد پنل شوید.
            برای این منظور باید ابتدا به تلگرام کانکت شوید!
          </p>
          <Button
            href="https://t.me/sefareshtabligh_bot?start=panel"
            target="_blank"
            class="mt-3"
            color="teal"
          >
            ثبت‌نام با ربات تبلیغات
          </Button>
          <Button
            href="https://t.me/mnrG1bot?start=panel"
            target="_blank"
            class="mt-3"
            color="red"
          >
            ثبت‌نام با ربات گروه
          </Button>
          <Button
            href="https://t.me/mnrcbot?start=panel"
            target="_blank"
            class="mt-3"
            color="cyan"
          >
            ثبت‌نام با ربات کانال
          </Button>
          <p class="mt-5 text-justify">
            شما میتوانید به صورت مستقیم به یکی از ربات ها پیام دهید و با زدن دکمه ورود به
            پنل و دریافت لینک از ربات وارد پنل شوید
          </p>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrPassword from "@/components/mnr/MnrPassword.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    Icon,
    MnrText,
    MnrPassword,
    Button,
    BodySimple,
  },
};
</script>
